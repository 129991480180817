<template>
  <div class="qimuindex">
    <div class="nav">
      <div class="content">
        <img src="../assets/logo.png" alt="">
        <div class="right">
          <ul>
            <li @click="goModel('#banner')" :class="{'active':active == '#banner'}">首页</li>
            <li @click="goModel('#business')" :class="{'active':active == '#business'}">业务</li>
            <li @click="goModel('#process')" :class="{'active':active == '#process'}">流程</li>
            <li @click="goModel('#assurance')" :class="{'active':active == '#assurance'}">保障</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="banner" id="banner">
      <img src="../assets/banner.png" alt="">
    </div>
    <div class="core_business model" id="business">
      <div class="content">
        <h3>核心业务</h3>
        <h4>凭借多年的技术积累，为客户提供完善、优质的服务！</h4>
        <div class="business_list">
          <div class="list_item">
            <div class="item_img">
              <img src="../assets/business1.png" alt="">
            </div>
            <div class="item_content">
              <p>软件开发</p>
              <span>
                重点行业应用开发(SaaS、PaaS、CRM、HCM、监管报送平台、系统搭建、人工智能助理)、大数据平台开发、商业智能、App开发、ERP、云平台、智能终端、产品化解决方案
              </span>
            </div>
          </div>
          <div class="list_item">
            <div class="item_img">
              <img src="../assets/business2.png" alt="">
            </div>
            <div class="item_content">
              <p>测试</p>
              <span>
                软件产品测试、应用软件测试、测试平台及产品、测试解决方案
              </span>
            </div>
          </div>
          <div class="list_item">
            <div class="item_img">
              <img src="../assets/business3.png" alt="">
            </div>
            <div class="item_content">
              <p>运维</p>
              <span>
                数据库维护(SQL Server、Oracle. MySQL)、操作系统维护(Windows、 Linux、Unix等常用系统)、服务器硬件设备维护、网络设备维护、运维管理平台
              </span>
            </div>
          </div>
          <div class="list_item">
            <div class="item_img">
              <img src="../assets/business4.png" alt="">
            </div>
            <div class="item_content">
              <p>运营服务</p>
              <span>
                IT咨询、IT服务、业务流程外包(BPO)、云/基础设施的管理、线上营销、数据采集与标注、内容管理和营销、设计服务、本地化、智能客服、大数据分析
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="service_process model" id="process">
      <div class="content">
        <h3>服务流程</h3>
        <h4>完善的服务流程，为客户项目快速上线保驾护航！</h4>
      </div>
      <img src="../assets/process.png" alt="">
    </div>
    <div class="security_assurance model" id="assurance">
      <div class="content">
        <h3>安全保障</h3>
        <h4>经过各种严苛的测试与验证，让系统坚不可摧！</h4>
        <div class="assurance_list">
          <div class="list_item">
            <img src="../assets/assurance1.png" alt="">
            <span>标准化的软件<br/>项目开发流程</span>
          </div>
          <div class="list_item">
            <img src="../assets/assurance2.png" alt="">
            <span>专业的白盒测<br/>试、黑盒测试</span>
          </div>
          <div class="list_item">
            <img src="../assets/assurance3.png" alt="">
            <span>通过软件评<br/>测中心检测</span>
          </div>
          <div class="list_item">
            <img src="../assets/assurance4.png" alt="">
            <span>专业三级<br/>安全扫描</span>
          </div>
          <div class="list_item mt">
            <img src="../assets/assurance5.png" alt="">
            <span>众多社交、教育<br/>行业在用</span>
          </div>
          <div class="list_item mt">
            <img src="../assets/assurance6.png" alt="">
            <span>采用https安全<br/>链接部署</span>
          </div>
          <div class="list_item mt">
            <img src="../assets/assurance7.png" alt="">
            <span>高级别检测认证</span>
          </div>
          <div class="list_item mt">
            <img src="../assets/assurance8.png" alt="">
            <span>定制型阿里云服务器，<br/>秒级回滚，实时备份</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="content">
        <div class="left">
          <p>Address</p>
          <div class="name">北京启慕科技发展有限公司</div>
          <div class="address">北京市朝阳区京顺路5号7号楼C座2层C210室</div>
        </div>
        <div class="center">
          <p>Contact us</p>
          <div class="phone">010-64094806</div>
        </div>
        <div class="right">
          <p>Navigation</p>
          <ul>
            <li>首页</li>
            <li>业务</li>
            <li>流程</li>
            <li>保障</li>
          </ul>
        </div>
      </div>
      <div class="footer_put_records">
        <div class="records_content">
          <p>北京启慕科技有限公司</p>
          <a target="_blank" href="http://beian.miit.gov.cn/">
            <span>京ICP备2023033060号-1</span>
          </a>
          <div class="police">
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502054271"
            >
              <img
                src="../assets/policeshield.png"
                style="float: left"
              />
              <p
                style="
                  float: left;
                  height: 20px;
                  line-height: 20px;
                  margin: 0px 0px 0px 5px;
                  color: #939393;
                "
              >
                京公网安备11010502054271号
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  let active = ref('#banner');
  function goModel(id){
    active.value = id
    document.querySelector(id).scrollIntoView({
      behavior: "smooth"
    })
  }
</script>

<style lang="less" scoped>
  .qimuindex{
    width: 100%;
    height: 100%;
    .content{
      width: 1400px;
      margin: 0 auto;
    }
    img{
      width: 100%;
    }
    .model{
      padding-top: 85px;
      h3{
        font-size: 44px;
        font-weight: 600;
      }
      h4{
        font-size: 40px;
        margin-bottom: 75px;
      }
    }
    .nav{
      width: 100%;
      height: 72px;
      position: fixed;
      top: 0;
      background-color: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(51,51,51,0.16);
      z-index: 10;
      .content{
        display: flex;
        align-items:center;
        justify-content: space-between;
        img{
          width: 114px;
          height: 38px;
        }
        .right{
          font-size: 16px;
          color: #333;
          ul{
            display: flex;
            align-items: center;
            li{
              height: 72px;
              line-height: 72px;
              padding: 0 25px;
              position: relative;
              cursor: pointer;
            }
            .active{
              color: #D83945;
              // &::after{
              //   content: '';
              //   position: absolute;
              //   width: 20px;
              //   height: 4px;
              //   background: #D83945;
              //   bottom: 0;
              //   left: 50%;
              //   right: 0;
              //   transform: translate(-50%);
              // }
            }
          }
        }
      }
    }
    .banner{
      width: 100%;
      margin-top: 72px;
      img{
        width: 100%;
      }
    }
    .core_business{
      width: 100%;
      color: #061332;
      .business_list{
        display: flex;
        justify-content: space-between;
        .list_item{
          width: 338px;
          height: 430px;
          overflow: hidden;
          .item_img{
            overflow: hidden;
          }
          img{
            width: 100%;
            height: 201px;
            transition: 0.5s;
            &:hover{
              -webkit-transform: scale(1.5);
              transform: scale(1.5);
            }
          }
          .item_content{
            padding: 28px 23px;
            height: 229px;
            box-sizing: border-box;
            color: #333;
            border: 1px solid #E8E8E8;
            p{
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 16px;
            }
            span{
              display: block;
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
    .security_assurance{
      margin-bottom: 75px;
      .assurance_list{
        width: 1400px;
        display: flex;
        flex-wrap: wrap;
        .list_item{
          width: 350px;
          display: flex;
          align-items: center;
          img{
            width: 100px;
            height: 100px;
            margin-right: 12px;
          }
          span{
            font-size: 20px;
            color: #333;
          }
        }
        .mt{
          margin-top: 60px;
        }
      }
    }
    .footer{
      height: 344px;
      background: #06101E;
      border: 1px solid #979797;
      padding-top: 35px;
      color: #ffffff;
      .content{
        display: flex;
        justify-content: space-between;
      }
      .left,.center,.right{
        flex: 1;
        p{
          font-size: 22px;
          margin-bottom: 24px;
        }
        .name{
          font-size: 20px;
          margin-bottom: 4px;
        }
        .address{
          font-size: 16px;
        }
        .phone{
          font-size: 26px;
        }
        li{
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
      .footer_put_records {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #ffffff;
        span {
          line-height: 14px;
          font-size: 16px;
        }
        a {
          color: #ffffff;
          font-size: 14px;
        }
        .police {
          margin-left: 10px;
          a {
            display: flex;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
        .records_content {
          display: flex;
          align-items: center;
          p {
            margin: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>